const themeColors = {
	chartcolors: {
		robin: '#3F5ECC',
		dodgerBlue: '#2F80ED',
		mediumOrchid: '#BB6BD9',
		seaBuckthorn: '#F2994A',
		seaGreen: '#219653',
		turquoiseBlue: '#56CCF2',
		festivalOrange: '#F2C94C',
		silver: '#BDBDBD',
		outrageousOrange: '#FF6633',
		roseBud: '#FFB399',
		canary: '#FFFF99',
		deepSkyBlue: '#00B3E6',
		goldTips: '#E6B333',
		royalBlue: '#3366E6',
		avocado: '#999966',
		mintGreen: '#99FF99',
		chestnut: '#B34D4D',
		lima: '#80B300',
		olive: '#809900',
		beautyBush: '#E6B3B3',
		danube: '#6680B3',
		oliveDrab: '#66991A',
		lavenderRose: '#FF99E6',
		electricLime: '#CCFF1A',
		radicalRed: '#FF1A66',
		harleyOrange: '#E6331A',
		turquoise: '#33FFCC',
		gladeGreen: '#66994D',
		hemlock: '#66664D',
		vidaLoca: '#4D8000',
		rust: '#B33300',
		red: '#FF0000', // Adding more colors, we need to get better colors from design team
		blue: '#0000FF',
		green: '#00FF00',
		yellow: '#FFFF00',
		purple: '#800080',
		cyan: '#00FFFF',
		magenta: '#FF00FF',
		orange: '#FFA500',
		pink: '#FFC0CB',
		brown: '#A52A2A',
		teal: '#008080',
		lime: '#00FF00',
		maroon: '#800000',
		navy: '#000080',
		aquamarine: '#7FFFD4',
		gold: '#FFD700',
		gray: '#808080',
		skyBlue: '#87CEEB',
		indigo: '#4B0082',
		slateGray: '#708090',
		chocolate: '#D2691E',
		tomato: '#FF6347',
		steelBlue: '#4682B4',
		peru: '#CD853F',
		darkOliveGreen: '#556B2F',
		indianRed: '#CD5C5C',
		mediumSlateBlue: '#7B68EE',
		rosyBrown: '#BC8F8F',
		darkSlateGray: '#2F4F4F',
		mediumAquamarine: '#66CDAA',
		lavender: '#E6E6FA',
		thistle: '#D8BFD8',
		salmon: '#FA8072',
		darkSalmon: '#E9967A',
		paleVioletRed: '#DB7093',
		mediumPurple: '#9370DB',
		darkOrchid: '#9932CC',
		lawnGreen: '#7CFC00',
		mediumSeaGreen: '#3CB371',
		lightCoral: '#F08080',
		darkSeaGreen: '#8FBC8F',
		sandyBrown: '#F4A460',
		darkKhaki: '#BDB76B',
		cornflowerBlue: '#6495ED',
		mediumVioletRed: '#C71585',
		paleGreen: '#98FB98',
	},
	lightModeColor: {
		robin: '#3F5ECC',
		dodgerBlueDark: '#0C6EED',
		steelgrey: '#2f4b7c',
		steelpurple: '#665191',
		steelindigo: '#a05195',
		steelpink: '#d45087',
		steelcoral: '#f95d6a',
		steelorange: '#ff7c43',
		steelgold: '#ffa600',
		steelrust: '#de425b',
		steelgreen: '#41967e',
		mediumOrchidDark: '#C326FD',
		seaBuckthornDark: '#E66E05',
		seaGreen: '#219653',
		turquoiseBlueDark: '#0099CC',
		silverDark: '#757575',
		outrageousOrangeDark: '#F9521A',
		roseBudDark: '#EB6437',
		deepSkyBlueDark: '#0595BD',
		royalBlue: '#3366E6',
		avocadoDark: '#8E8E29',
		mintGreenDark: '#00C700',
		chestnut: '#B34D4D',
		limaDark: '#6E9900',
		olive: '#809900',
		beautyBushDark: '#E25555',
		danube: '#6680B3',
		oliveDrab: '#66991A',
		lavenderRoseDark: '#F024BD',
		electricLimeDark: '#84A800',
		radicalRed: '#FF1A66',
		harleyOrange: '#E6331A',
		gladeGreen: '#66994D',
		hemlock: '#66664D',
		vidaLoca: '#4D8000',
		rust: '#B33300',
		red: '#FF0000', // Adding more colors, we need to get better colors from design team
		blue: '#0000FF',
		green: '#00FF00',
		purple: '#800080',
		magentaDark: '#EB00EB',
		pinkDark: '#FF3D5E',
		brown: '#A52A2A',
		teal: '#008080',
		limeDark: '#07A207',
		maroon: '#800000',
		navy: '#000080',
		gray: '#808080',
		skyBlueDark: '#0CA7E4',
		indigo: '#4B0082',
		slateGray: '#708090',
		chocolate: '#D2691E',
		tomato: '#FF6347',
		steelBlue: '#4682B4',
		peruDark: '#D16E0A',
		darkOliveGreen: '#556B2F',
		indianRed: '#CD5C5C',
		mediumSlateBlue: '#7B68EE',
		rosyBrownDark: '#CB4848',
		darkSlateGray: '#2F4F4F',
		fuchsia: '#FF0AFF',
		salmonDark: '#FF432E',
		darkSalmonDark: '#D26541',
		paleVioletRedDark: '#E83089',
		mediumPurple: '#9370DB',
		darkOrchid: '#9932CC',
		mediumSeaGreenDark: '#109E50',
		lightCoralDark: '#F85959',
		darkSeaGreenDark: '#509F50',
		sandyBrownDark: '#D97117',
		darkKhakiDark: '#99900A',
		cornflowerBlueDark: '#3371E6',
		mediumVioletRed: '#C71585',
		paleGreenDark: '#0D910D',
	},
	errorColor: '#d32f2f',
	royalGrey: '#888888',
	matterhornGrey: '#555555',
	whiteCream: '#ffffffd5',
	white: '#ffffff',
	black: '#000000',
	darkGrey: '#262626',
	lightBlack: '#141414',
	lightgrey: '#ddd',
	lightWhite: '#ffffffd9',
	borderLightGrey: '#d9d9d9',
	borderDarkGrey: '#424242',
	gainsboro: '#DBDBDB',
	navyBlue: '#1668DC',
	lightSkyBlue: '#8DCFF8',
	neroBlack: '#1d1d1d',
	snowWhite: '#fafafa',
	gamboge: '#D89614',
	bckgGrey: '#1d1d1d',
	lightBlue: '#177ddc',
	buttonSuccessRgb: '73, 170, 25',
	red: '#E84749',
};

export { themeColors };
